import { EnvironmentId } from 'src/app/common';

export const environment = {
  production: false,
  apiBaseUrl: 'https://hc-nurseportal-api-ncus-test-app-win.azurewebsites.net/',
  timeBaseUrl: 'https://hctimeuserapi-test.azurewebsites.net/api/user',
  expenseManagementUrl: 'https://hcarcherapi-test.azurewebsites.net/api/ExpenseRequest',
  onDemandBaseUrl: 'https://ondemand-test.hctravelnursing.com',
  credentiallySSOUrl: 'https://training.hctn.credentially.io/',
  marketingBaseUrl: 'https://www.hctravelnursing.com',
  nurseSentimentApiBaseUrl: 'https://hc-nonprod-apim.azure-api.net/nursesentiment/test',
  ionicAuthConfig: {
    clientId: 'db475add-3ab2-45b2-8b15-3ce84526f606',
    discoveryUrl: 'https://my-test.ondemandtravelnursing.com/HealthCarouselB2cTest.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_signup_signin',
    passwordResetDiscoveryUrl: 'https://my-test.ondemandtravelnursing.com/HealthCarouselB2cTest.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=b2c_1a_passwordreset',
    passwordChangeDiscoveryUrl: 'https://my-test.ondemandtravelnursing.com/HealthCarouselB2cTest.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_PasswordChange',

    signupDiscoveryUrl: 'https://my-test.ondemandtravelnursing.com/HealthCarouselB2cTest.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=b2c_1a_signup',
    internalLoginAuthority: 'https://login.microsoftonline.com/ea791863-b17d-43ec-90ba-148d3fa0a86f',

    webRedirectUri: 'https://ondemand-test.hctravelnursing.com/redirect',
    appRedirectUri: 'com.hctravelnursing.ondemand://redirect',
    webLogoutUrl: 'https://ondemand-test.hctravelnursing.com/logout',
    appLogoutUrl: 'com.hctravelnursing.ondemand://logout',
    defaultScopes: 'https://HealthCarouselB2cTest.onmicrosoft.com/api/write openid offline_access',
    archerApi: {
      scopes: 'openid profile https://HealthCarouselB2cTest.onmicrosoft.com/f853ebd2-1c53-4200-a55e-47599cdded6b/access_as_user',
      audience: 'f853ebd2-1c53-4200-a55e-47599cdded6b'
    },
    internalScopes: 'api://b179983a-e57a-4b0b-82a2-b9535d8bdc39/access_as_user',
    timeApi: {
      scopes: 'openid profile https://HealthCarouselB2cTest.onmicrosoft.com/3310e80e-f698-491b-b884-faa059750c76/assignment.read',
      audience: '3310e80e-f698-491b-b884-faa059750c76'
    },
    hcinApi: {
      scopes: 'https://HealthCarouselB2cTest.onmicrosoft.com/default/access_as_user openid profile',
      audience: 'db581ab5-819a-4f4b-8156-7b2b0d1c1a31'
    },
    audience: 'HealthCarouselB2cTest'
  },
  amplitudeAPIKey: 'client-hWSnCFiMDyp5x4bdT53fZa9lcfAOdufc',
  environmentId: EnvironmentId.Test,
  appInsightsLey: '3abbd871-0f89-41cf-b5ab-6fb19b7b04ba',
  sentryEnvironment: 'test',
  sentrySampleRate: 0.4,
  appBaseUrl: 'https://ondemand-test.hctravelnursing.com',
  releaseVersion: '9.42.0',
  ocpUsersApimSubscriptionKey: '52909d22e51b419c8c31b9568800e1c5',
  usersApiBaseUrl: 'https://hc-nonprod-apim.azure-api.net/test/hctn/users/',
  ocpGuestApimSubscriptionKey: 'b0552fae3b9d45009ab68ebb33b104bc',
  guestApiBaseUrl: 'https://hc-nonprod-apim.azure-api.net/guestservice/test/',
  ocpCandidateJobsApimSubscriptionKey: '0b072a22c5f04ba28ad28bfdddabd4b2',
  candidateJobsApiBaseUrl: 'https://hc-nonprod-apim.azure-api.net/candidate/jobs/test/',
  internationalApiBaseUrl: 'https://hc-nonprod-apim.azure-api.net/hcin/test/',
  internationalApimSubscriptionKey: '51de5bd0b5004b1b9ed01fdc32dff565',
};
